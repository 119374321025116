<template>
  <div class="container">
    <div class="logo"></div>
    <div class="loginTitle">
      <div class="titleLogo"></div>
      <div class="text">进入系统，了解详情></div>
      <div class="titleImg">云体系平台</div>
    </div>
    <div class="formBox" v-show="registerFlge">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        text-color="#B3B3B3"
        active-text-color="#000000"
        @select="handleSelect"
      >
        <el-menu-item index="1">账号登录</el-menu-item>
        <el-menu-item index="2">手机登录</el-menu-item>
      </el-menu>
      <!-- 账号登录 -->
      <el-form
        :model="form"
        ref="form"
        :rules="formRegisterRules"
        v-show="activeIndex == 1"
      >
        <el-form-item>
          <div class="imgBox">
            <img
              src="../../assets/images/login/onaccount.png"
              alt
              v-if="UserNoFlge"
            />
            <img src="../../assets/images/login/account.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="form.UserNo"
            placeholder="请输入账号"
            @focus="focus('UserNoFlge')"
            @input="input($event, 'UserNoFlge')"
          />
        </el-form-item>
        <el-form-item>
          <div class="imgBox">
            <img
              src="../../assets/images/login/onpwd.png"
              alt
              v-if="PassWordFlge"
            />
            <img src="../../assets/images/login/pwd.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="form.PassWord"
            type="password"
            placeholder="请输入密码"
            @focus="focus('PassWordFlge')"
            @input="input($event, 'PassWordFlge')"
          />
        </el-form-item>
        <el-form-item prop="UserCode">
          <div class="imgBox">
            <img
              src="../../assets/images/login/oncode.png"
              alt
              v-if="UserCodeFlge"
            />
            <img src="../../assets/images/login/code.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="form.UserCode"
            placeholder="请输入验证码"
            @focus="focus('UserCodeFlge')"
            @input="input($event, 'UserCodeFlge')"
            maxlength="4"
          />
          <span class="codeBox" @click="clickCode">
            <img :src="codeUrl" alt style="width: 100%; height: 100%" />
          </span>
        </el-form-item>
        <div class="pwdBox">
          <div>
            <el-checkbox v-model="form.checked" size="large" />
            <span>记住密码</span>
          </div>
          <div @click="forget">忘记密码？</div>
        </div>
        <el-form-item>
          <el-button
            @click="accountLogin"
            :class="
              form.UserNo !== '' && form.PassWord !== '' && form.UserCode !== ''
                ? 'btn'
                : ''
            "
          >
            登录
          </el-button>
        </el-form-item>
        <!-- <div class="otherLogin">
          <div>
            <span>其他方式登录</span>
            <img src="../../assets/images/login/qq.png" alt />
            <img src="../../assets/images/login/wx.png" alt />
          </div>
          <div>
            <span>新用户&nbsp;</span>
            <span @click="register">注册</span>
          </div>
        </div> -->
        <div class="otherLogin2">
          <p class="p1">——————— <span>没有账号？</span>———————</p>
          <p class="p2" @click="register">
            立即注册<img src="../../assets/images/login/finger.png" />
          </p>
        </div>
      </el-form>
      <!-- 手机登录 -->
      <el-form
        :model="formPhone"
        ref="formPhone"
        :rules="formRegisterRules"
        v-show="activeIndex == 2"
      >
        <el-form-item prop="UserPhone">
          <div class="imgBox">
            <img
              src="../../assets/images/login/onphone.png"
              alt
              v-if="UserPhoneFlge"
            />
            <img src="../../assets/images/login/phone.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formPhone.UserPhone"
            placeholder="请输入手机号"
            @focus="focus('UserPhoneFlge')"
            @input="input($event, 'UserPhoneFlge')"
            maxlength="11"
          />
        </el-form-item>
        <el-form-item prop="UserNumberCode">
          <div class="imgBox">
            <img
              src="../../assets/images/login/oncode.png"
              alt
              v-if="UserNumberCodeFlge"
            />
            <img src="../../assets/images/login/code.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formPhone.UserNumberCode"
            placeholder="4位数字验证码"
            @focus="focus('UserNumberCodeFlge')"
            @input="input($event, 'UserNumberCodeFlge')"
            maxlength="4"
          />
          <span
            v-if="timeFlag"
            class="phoneCodeBox"
            @click="sendCode(formPhone.UserPhone)"
            >发送验证码</span
          >
          <span
            style="background: #f6f6f6"
            v-else
            class="phoneCodeBox colorCode"
            >{{ time + "S" }}</span
          >
        </el-form-item>
        <div class="pwdBox" style="margin-bottom: 0.98rem">
          <!-- <div>
            <el-checkbox v-model="formPhone.checked" size="large" />
            <span>记住密码</span>
          </div>
          <div @click="forget">忘记密码？</div> -->
        </div>
        <el-form-item>
          <el-button
            @click="phoneLogin"
            :class="
              formPhone.UserPhone !== '' && formPhone.UserNumberCode !== ''
                ? 'btn'
                : ''
            "
          >
            登录
          </el-button>
        </el-form-item>
        <!-- <div class="otherLogin">
          <div>
            <span>其他方式登录</span>
            <img src="../../assets/images/login/qq.png" alt />
            <img src="../../assets/images/login/wx.png" alt />
          </div>
          <div>
            <span>新用户&nbsp;</span>
            <span @click="register">注册</span>
          </div>
        </div> -->
        <div class="otherLogin2">
          <p class="p1">——————— <span>没有账号？</span>———————</p>
          <p class="p2" @click="register">
            立即注册<img src="../../assets/images/login/finger.png" />
          </p>
        </div>
      </el-form>
    </div>
    <!-- 注册账号 -->
    <div class="formBox formRegisterFlgeBox" v-show="registerFlge == false">
      <div class="title">注册账号</div>
      <el-form
        :model="formRegister"
        ref="formRegister"
        :rules="formRegisterRules"
      >
        <el-form-item>
          <div class="imgBox">
            <img
              src="../../assets/images/login/onaccount.png"
              alt
              v-if="UserNoRegisterFlge"
            />
            <img src="../../assets/images/login/account.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formRegister.UserNo"
            placeholder="请输入用户名"
            @focus="focus('UserNoRegisterFlge')"
            @input="input($event, 'UserNoRegisterFlge')"
          />
        </el-form-item>
        <el-form-item prop="UserPhone">
          <div class="imgBox">
            <img
              src="../../assets/images/login/onphone.png"
              alt
              v-if="UserPhoneRegisterFlge"
            />
            <img src="../../assets/images/login/phone.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formRegister.UserPhone"
            placeholder="请输入手机号"
            @focus="focus('UserPhoneRegisterFlge')"
            @input="input($event, 'UserPhoneRegisterFlge')"
            maxlength="11"
          />
        </el-form-item>
        <el-form-item prop="PassWord">
          <div class="imgBox">
            <img
              src="../../assets/images/login/onpwd.png"
              alt
              v-if="PassWordRegisterFlge"
            />
            <img src="../../assets/images/login/pwd.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formRegister.PassWord"
            autocomplete="new-password"
            type="password"
            placeholder="请输入至少8位密码，包含数字和字母"
            @focus="focus('PassWordRegisterFlge')"
            @input="input($event, 'PassWordRegisterFlge')"
            minlength="8"
          />
        </el-form-item>
        <el-form-item prop="AgainPassWord">
          <div class="imgBox">
            <img
              src="../../assets/images/login/onpwd.png"
              alt
              v-if="AgainPassWordRegisterFlge"
            />
            <img src="../../assets/images/login/pwd.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formRegister.AgainPassWord"
            type="password"
            placeholder="请再次输入密码"
            @focus="focus('AgainPassWordRegisterFlge')"
            @input="input($event, 'AgainPassWordRegisterFlge')"
          />
        </el-form-item>
        <el-form-item prop="UserNumberCode">
          <div class="imgBox">
            <img
              src="../../assets/images/login/oncode.png"
              alt
              v-if="UserNumberCodeRegisterFlge"
            />
            <img src="../../assets/images/login/code.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formRegister.UserNumberCode"
            placeholder="4位数字验证码"
            @focus="focus('UserNumberCodeRegisterFlge')"
            @input="input($event, 'UserNumberCodeRegisterFlge')"
            maxlength="4"
          />
          <span
            v-if="timeFlag"
            class="phoneCodeBox"
            @click="sendCode(formRegister.UserPhone)"
            >发送验证码</span
          >
          <span
            style="background: #f6f6f6"
            v-else
            class="phoneCodeBox colorCode"
            >{{ time + "S" }}</span
          >
        </el-form-item>
        <el-form-item prop="UserCode">
          <div class="imgBox">
            <img
              src="../../assets/images/login/oncode.png"
              alt
              v-if="UserCodeRegisterFlge"
            />
            <img src="../../assets/images/login/code.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formRegister.UserCode"
            placeholder="请输入验证码"
            @focus="focus('UserCodeRegisterFlge')"
            @input="input($event, 'UserCodeRegisterFlge')"
            maxlength="4"
          />
          <span class="codeBox" @click="clickCode">
            <img :src="codeUrl" alt style="width: 100%; height: 100%" />
          </span>
        </el-form-item>
        <el-form-item style="margin-top: 0.62rem">
          <el-button
            @click="submitRegister"
            :class="
              formRegister.UserNo !== '' &&
              formRegister.UserPhone !== '' &&
              formRegister.PassWord !== '' &&
              formRegister.AgainPassWord !== '' &&
              formRegister.UserNumberCode !== '' &&
              formRegister.UserCode !== ''
                ? 'btn'
                : ''
            "
          >
            确定创建账号
          </el-button>
        </el-form-item>
        <div class="ringBox">
          <span>已有账号&nbsp;</span>
          <span @click="ringBtn">立刻登陆~</span>
        </div>
      </el-form>
    </div>
    <!-- 忘记密码 -->
    <div class="formBox" style="z-index: 99" v-show="!forgetFlge">
      <div class="title">忘记密码</div>
      <el-form :model="formForget" ref="formForget" :rules="formRegisterRules">
        <el-form-item prop="UserPhone">
          <div class="imgBox">
            <img
              src="../../assets/images/login/onphone.png"
              alt
              v-if="forgetPhoneFlge"
            />
            <img src="../../assets/images/login/phone.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formForget.UserPhone"
            placeholder="请输入手机号"
            @focus="focus('forgetPhoneFlge')"
            @input="input($event, 'forgetPhoneFlge')"
            maxlength="11"
          />
        </el-form-item>
        <el-form-item prop="UserNumberCode">
          <div class="imgBox">
            <img
              src="../../assets/images/login/oncode.png"
              alt
              v-if="forgetNumberCodeFlge"
            />
            <img src="../../assets/images/login/code.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formForget.UserNumberCode"
            placeholder="4位数字验证码"
            @focus="focus('forgetNumberCodeFlge')"
            @input="input($event, 'forgetNumberCodeFlge')"
            maxlength="4"
          />
          <span
            v-if="timeFlag"
            class="phoneCodeBox"
            @click="sendCode(formForget.UserPhone)"
            >发送验证码</span
          >
          <span
            style="background: #f6f6f6"
            v-else
            class="phoneCodeBox colorCode"
            >{{ time + "S" }}</span
          >
        </el-form-item>
        <el-form-item prop="PassWord">
          <div class="imgBox">
            <img
              src="../../assets/images/login/onpwd.png"
              alt
              v-if="forgetPassWordFlge"
            />
            <img src="../../assets/images/login/pwd.png" alt v-else />
            <el-divider direction="vertical" />
          </div>
          <el-input
            v-model="formForget.PassWord"
            autocomplete="new-password"
            type="password"
            placeholder="请输入至少8位密码，包含数字和字母"
            @focus="focus('forgetPassWordFlge')"
            @input="input($event, 'forgetPassWordFlge')"
            minlength="8"
          />
        </el-form-item>
        <el-form-item style="margin-top: 0.57rem">
          <el-button
            @click="submitForget"
            :class="
              formForget.UserPhone !== '' &&
              formForget.UserNumberCode !== '' &&
              formForget.PassWord !== ''
                ? 'btn'
                : ''
            "
          >
            确定
          </el-button>
        </el-form-item>
        <div class="ringBox">
          <span>已有账号&nbsp;</span>
          <span @click="ringBtn">立刻登陆~</span>
        </div>
      </el-form>
    </div>
    <!-- <div class="footer">广州市景翔信息科技有限公司</div> -->
    <div class="bottom-box">
      <div>
        <img src="../../assets/images/img1/cname.png" alt="" />
        <span @click="$router.push('/home-page')">景翔信息科技有限公司</span
        ><a href="http://beian.miit.gov.cn" target="_blank"
          >(粤ICP备2021114949号-4)</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { get, getUserCode, post, put } from "../../api/http";
// import { setRemPc } from "../../modules/SetRem"; // Createguid
const Base64 = require("js-base64").Base64;
export default {
  data() {
    let checkPwd = (formRegisterRules, value, callback) => {
      if (value.trim().length == 0) {
        // callback(new Error("请再次输入密码"));
        this.$message.error("请再次输入密码");
      } else if (value != this.formRegister.PassWord) {
        // callback(new Error("两次密码不一致"));
        this.$message.error("两次密码不一致");
      } else {
        callback();
      }
    };
    return {
      activeIndex: "1",
      registerFlge: true,
      codeUrl: "",
      time: 60,
      timeFlag: true,
      forgetFlge: true,
      form: {
        UserNo: "",
        PassWord: "",
        UserCode: null,
        GuId: "",
        checked: false,
      },
      formPhone: {
        UserPhone: "",
        UserNumberCode: "",
        checked: false,
      },
      formRegister: {
        UserNo: "",
        UserPhone: "",
        PassWord: "",
        AgainPassWord: "",
        UserNumberCode: "",
        UserCode: "",
        GuId: "",
      },
      formForget: {
        UserPhone: "",
        UserNumberCode: "",
        PassWord: "",
      },
      UserNoFlge: false,
      PassWordFlge: false,
      UserCodeFlge: false,
      UserPhoneFlge: false,
      UserNumberCodeFlge: false,
      UserNoRegisterFlge: false,
      UserPhoneRegisterFlge: false,
      PassWordRegisterFlge: false,
      AgainPassWordRegisterFlge: false,
      UserNumberCodeRegisterFlge: false,
      UserCodeRegisterFlge: false,
      forgetPhoneFlge: false,
      forgetNumberCodeFlge: false,
      forgetPassWordFlge: false,
      formRegisterRules: {
        UserPhone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: "请正确输入11位手机号码",
            trigger: "blur",
          },
        ],
        UserCode: [
          { required: true, pattern: /^[0-9]*$/, message: "请输入数字" },
        ],
        UserNumberCode: [
          { required: true, pattern: /^[0-9]*$/, message: "请输入数字" },
        ],
        PassWord: [
          {
            required: true,
            message: "请输入密码",
            transform: (value) => value,
            trigger: "blur",
          },
          {
            type: "string",
            message: "请输入不包含空格的字符",
            trigger: "blur",
            transform(value) {
              if (value && value.indexOf(" ") === -1) {
                return value;
              } else {
                return false;
              }
            },
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              var passwordreg = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/;
              if (!passwordreg.test(value)) {
                callback(
                  this.$message.error("请输入至少8位密码，包含数字与字母！")
                );
              } else {
                callback();
              }
            },
          },
        ],
        AgainPassWord: [
          {
            validator: checkPwd,
            required: true,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    // setRemPc();
    // window.addEventListener("resize", setRemPc); //浏览器窗口大小改变时调用rem换算方法
    this.getUserCode();
    if (window.localStorage.getItem("userAccount")) {
      this.form = JSON.parse(window.localStorage.getItem("userAccount"));
      this.form.PassWord = Base64.decode(this.form.PassWord).slice(0, -2);
      // this.form.PassWord = (Base64.decode(this.form.PassWord)).slice(0,-4);
      this.UserNoFlge = true;
      this.PassWordFlge = true;
    }
    if (window.localStorage.getItem("UserPhone")) {
      this.formPhone.UserPhone = JSON.parse(
        window.localStorage.getItem("UserPhone")
      );
      this.formPhone.checked = true;
      this.UserPhoneFlge = true;
    }
    if (this.$route.query.showRegister) this.registerFlge = false;
  },
  methods: {
    handleSelect(e) {
      this.activeIndex = e;
    },
    focus(e) {
      this[e] = true;
    },
    input(e, flag) {
      if (e === "") {
        this[flag] = false;
      } else {
        this[flag] = true;
      }
    },
    // 注册
    register() {
      this.registerFlge = false;
    },
    // 切换登录
    ringBtn() {
      this.registerFlge = true;
      this.forgetFlge = true;
      this.timeFlag = true;
    },
    // 获取验证码
    getUserCode() {
      getUserCode("/api/UserCode")
        .then((resp) => {
          if (resp.status == 200) {
            this.codeUrl = window.URL.createObjectURL(resp.data);
            this.form.GuId = resp.headers["content-type"].split("; ")[1];
            this.formRegister.GuId =
              resp.headers["content-type"].split("; ")[1];
          } else {
            this.$message.error("获取失败");
          }
        })
        .catch(() => {
          this.$message.error("获取失败");
        });
    },
    // 点击刷新验证码
    clickCode() {
      this.getUserCode();
    },
    // 账号登录
    accountLogin() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          get("/api/DoLogin/DoLogin", this.form)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "登录成功",
                  duration: 1500,
                });
                if (this.form.checked == true) {
                  this.form.UserCode = "";
                  this.form.GuId = "";
                  this.form.PassWord = Base64.encode(
                    this.form.PassWord + this.form.UserNo.slice(0, 2)
                  );
                  // (this.form.PassWord = Base64.encode(this.form.PassWord + Createguid().slice(0,4)));
                  window.localStorage.setItem(
                    "userAccount",
                    JSON.stringify(this.form)
                  );
                } else {
                  window.localStorage.removeItem("userAccount");
                }
                if (resp.data.upCompanyId === 0) {
                  resp.data.upCompanyId = "";
                }
                if (resp.data.upDeptmentId === 0) {
                  resp.data.upDeptmentId = "";
                }
                this.setData(resp);
                if (resp.data.tenantId != 0) {
                  this.getCompanyList();
                }
                this.$router.push("./home");
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("登录失败");
            });
        }
      });
    },
    // 点击发送手机验证码
    sendCode(e) {
      if (e != "" && e != null) {
        get("/api/UserCode/GetPhoneCode", { phone: e }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.timeFlag = false;
            let timetimer = setInterval(() => {
              this.time--;
              if (this.time <= 0) {
                this.timeFlag = true;
                clearInterval(timetimer);
                this.time = 60;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 手机登录
    phoneLogin() {
      this.$refs["formPhone"].validate((valid) => {
        if (valid) {
          let form = {
            Phone: this.formPhone.UserPhone,
            PhoneCode: this.formPhone.UserNumberCode,
            checked: this.formPhone.checked,
          };
          get("/api/DoLogin/DoPhoneLogin", form)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "登录成功",
                  duration: 1500,
                });
                if (this.formPhone.checked == true) {
                  window.localStorage.setItem(
                    "UserPhone",
                    JSON.stringify(this.formPhone.UserPhone)
                  );
                } else {
                  window.localStorage.removeItem("UserPhone");
                }
                this.setData(resp);
                if (resp.data.tenantId != 0) {
                  this.getCompanyList();
                }
                this.$router.push("./home");
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("登录失败");
            });
        }
      });
    },
    // 注册账号
    submitRegister() {
      this.$refs["formRegister"].validate((valid) => {
        if (valid) {
          let form = {
            UserNo: this.formRegister.UserNo,
            UserName: this.formRegister.UserNo,
            Phone: this.formRegister.UserPhone,
            Pwd: this.formRegister.AgainPassWord,
            PhoneCode: this.formRegister.UserNumberCode,
            UserCode: this.formRegister.UserCode,
            GuId: this.formRegister.GuId,
          };
          post("/api/DoLogin/UserRegister", form)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "注册成功",
                });
                this.registerFlge = true;
                this.timeFlag = true;
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("注册失败");
            });
        }
      });
    },
    // 忘记密码
    forget() {
      this.forgetFlge = false;
    },
    // 忘记密码提交
    submitForget() {
      this.$refs["formForget"].validate((valid) => {
        if (valid) {
          put(
            "/api/DoLogin/ReturnPwd?Phone=" +
              this.formForget.UserPhone +
              "&PhoneCode=" +
              this.formForget.UserNumberCode +
              "&NewPwd=" +
              this.formForget.PassWord
          ).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "重置成功",
              });
              this.forgetFlge = true;
              this.timeFlag = true;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    setData(resp) {
      sessionStorage.setItem("userInfo", JSON.stringify(resp.data));
      this.$store.dispatch("userInfo", resp.data);
      sessionStorage.setItem("token", JSON.stringify(resp.data.userToken));
      sessionStorage.setItem("tenantId", JSON.stringify(resp.data.tenantId));
      this.$store.dispatch("tenantId", resp.data.tenantId);
    },
    getCompanyList() {
      get("/api/Company/GetAllByUser").then((resp) => {
        this.$store.dispatch("companyUserList", resp.data);
        sessionStorage.setItem("companyUserList", JSON.stringify(resp.data));
      });
      get("/api/Company/GetAll").then((resp) => {
        if (resp.code == 200) {
          this.$store.dispatch("companyList", resp.data);
          sessionStorage.setItem("companyList", JSON.stringify(resp.data));
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e0ebff !important;
  background: url("../../assets/images/login/loginBg.png") no-repeat;
  background-size: 100% 99%;

  .logo {
    position: absolute;
    top: 0.65rem;
    left: 1.11rem;
    width: 2rem;
    height: 0.44rem;
    // background: url("../../assets/images/login/loginLogo.png") no-repeat;
    background: url("../../assets/images/page1.png") no-repeat;
    background-size: 100% 100%;
  }

  .loginTitle {
    position: absolute;
    top: 2.78rem;
    left: 2.76rem;

    .titleLogo {
      background: url("../../assets/images/login/loginTitle.png") no-repeat;
      background-size: 100% 100%;
      width: 6.63rem;
      height: 0.48rem;
    }

    .text {
      height: 0.19rem;
      line-height: 0.19rem;
      font-size: 0.18rem;
      font-family: Microsoft YaHei;
      font-weight: 700 !important;
      color: #ffffff;
      margin: 0.35rem 0 0.16rem 0;
    }

    .titleImg {
      width: 3.69rem;
      height: 0.95rem;
      background: url("../../assets/images/login/titleBg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 0.3rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      line-height: 0.88rem;
    }
  }

  .formBox {
    position: absolute;
    top: 1.5rem;
    right: 3.61rem;
    width: 5.03rem;
    background: #ffffff;
    border: 2px solid #ecf0fa;
    border-radius: 0.18rem;
    padding: 0.46rem 0.45rem 0.44rem 0.52rem;
    box-sizing: border-box;
    .title {
      padding: 0 0 0.21rem 0.11rem;
      border-bottom: 2px solid #e6e6e6;
      box-sizing: border-box;
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 700;
      color: #000000;
      margin-bottom: 0.32rem;
    }

    .el-menu {
      height: 0.41rem;
    }
    .el-menu-item {
      height: 100%;
      display: flex;
      align-items: end;
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
    ::v-deep .el-menu-item.is-active {
      border-bottom: 2px solid #018dff !important;
    }

    .el-form {
      margin-top: 0.32rem;

      .el-form-item {
        position: relative;
        height: 0.5rem;
        display: flex;
        margin-bottom: 0.12rem;

        .imgBox {
          position: absolute;
          left: 0.16rem;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          display: flex;
          align-items: center;

          img {
            margin-right: 0.04rem;
          }
        }

        ::v-deep .el-form-item__content {
          display: flex;
          width: 100%;
          line-height: normal !important;
        }

        ::v-deep .el-input {
          height: 100% !important;
        }
        ::v-deep .el-input__inner {
          height: 100% !important;
          padding-left: 0.68rem;
          border-radius: 0.06rem;
          font-size: 0.16rem !important;
        }

        ::v-deep .el-form-item__error {
          display: none !important;
        }

        .codeBox {
          display: inline-block;
          width: 1.28rem;
          height: 0.5rem;
          border-radius: 0.06rem;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
          margin-left: 0.07rem;
          cursor: pointer;
        }

        .phoneCodeBox {
          display: inline-block;
          width: 1.28rem;
          height: 0.5rem;
          background: #ffffff;
          border: 2px solid #e5e5e5;
          border-radius: 0.06rem;
          box-sizing: border-box;
          margin-left: 0.07rem;
          font-size: 0.16rem;
          font-weight: 400;
          color: #3089e9;
          font-weight: bold;
          text-align: center;
          line-height: 0.46rem;
          cursor: pointer;
        }

        .colorCode {
          color: #999999 !important;
        }

        .el-button {
          width: 100%;
          height: 100% !important;
          background: #e5e5e5;
          border-radius: 0.06rem !important;
          border: none;
          cursor: pointer;
          font-size: 0.18rem !important;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #999999;
        }

        .btn {
          background: #018dff;
          color: #ffffff;
        }
      }

      .pwdBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.14rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #999999;
        height: 0.21rem;
        line-height: 0.21rem;
        margin-bottom: 0.36rem;

        & div:nth-child(1) {
          display: flex;
          align-items: center;

          ::v-deep .el-checkbox__inner {
            border: 2px solid #e5e5e5;
            border-radius: 0.04rem;
            width: 0.23rem;
            height: 0.21rem;
            margin-right: 0.1rem;
          }

          ::v-deep .el-checkbox__inner::after {
            height: 0.1rem;
            width: 0.05rem;
            left: 0.07rem;
          }
        }
        & > div:nth-child(2) {
          cursor: pointer;
        }
      }

      .otherLogin {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & div:nth-child(1) {
          display: flex;
          align-items: center;

          span {
            font-size: 0.14rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #999999;
          }

          img {
            margin-left: 0.05rem;
            cursor: pointer;
          }
        }

        & div:nth-child(2) {
          font-size: 0.14rem;
          font-family: Microsoft YaHei;
          font-weight: bold;

          & span:nth-child(1) {
            color: #999999;
          }

          & span:nth-child(2) {
            color: #f54d4c;
            cursor: pointer;
          }
        }
      }
    }
  }

  .formRegisterFlgeBox {
    position: absolute;
    top: 1.3rem;
    // top: 2.34rem;
    right: 3.61rem;
    width: 5.03rem;
    height: 6.67rem;
    background: #ffffff;
    border: 2px solid #ecf0fa;
    border-radius: 0.18rem;
    padding: 0.46rem 0.45rem 0.32rem 0.52rem;
    box-sizing: border-box;

    .title {
      padding: 0 0 0.21rem 0.11rem;
      border-bottom: 2px solid #e6e6e6;
      box-sizing: border-box;
      font-size: 0.2rem;
      font-family: Microsoft YaHei;
      font-weight: 700;
      color: #000000;
      margin-bottom: 0.32rem;
    }
  }
  .ringBox {
    margin-top: 0.26rem;
    text-align: center;
    font-size: 0.14rem;
    font-family: Microsoft YaHei;
    font-weight: bold;

    & span:nth-child(1) {
      color: #999999;
    }

    & span:nth-child(2) {
      color: #018dff;
      cursor: pointer;
    }
  }

  // .footer {
  //   position: absolute;
  //   bottom: 0.22rem;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   height: 0.18rem;
  //   line-height: 0.18rem;
  //   font-size: 0.18rem;
  //   font-family: SweiAliasLegCJKsc;
  //   font-weight: bold;
  //   color: #333333;
  // }
  .bottom-box {
    position: absolute;
    text-align: center;
    bottom: 9px;
    left: 0;
    right: 0;
    margin: auto;
    color: black;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      letter-spacing: 5px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
  }
}
.otherLogin2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  .p1 {
    font-size: 10px;
    color: rgb(231, 231, 231);
  }
  span {
    color: rgb(160, 160, 160);
  }
  .p2 {
    position: relative;
    width: 1.5rem;
    height: 0.55rem;
    background: url("../../assets/images/login/text.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    text-align: center;
    line-height: 0.7rem;
    font-weight: bold;
    margin-top: 5px;
    cursor: pointer;
    img {
      position: absolute;
      top: 30px;
      animation: zoom 0.8s infinite alternate;
      width: 20px;
    }
    @keyframes zoom {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.7);
      }
    }
  }
}
</style>